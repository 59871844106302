@import '../../../index.scss';


.company-page-hero-section {
    padding: 4rem;
    background-color: $primary;
    padding: 4rem;
    background-color: $primary;
    background-image: url(../../../assets/hero_background.png);
    background-repeat: no-repeat;
    background-position: 110%;

    .btn {
        font-size: 1.3rem;
    }
}

@include media-breakpoint-down(md) {
    .company-page-hero-section {
        padding: 2rem;
        background-image: none;

        .btn {
            font-size: 1rem;
        }
    }
}