@import '../../index.scss';


.carousel {
    .carousel-slide {
        background: linear-gradient(to right, $secondary, #ff366899);
        padding: 2rem;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        color: white;

        h3 {
            font-size: 1.8rem;
        }
        p {
            font-size: 1.1rem;
        }

        img {
            width: 85%;
        }
    }

    .carousel-inner {
        width: 85%;
        margin: 0 auto;

        img {
            max-height: 20rem;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: auto;
        opacity: unset;
        height: fit-content;
        margin: auto 0;

        i {
            font-size: 3rem;
            color: #afa9b4;
            opacity: 1;
        }

        i:hover {
            color: #848087;
        }
    }

    .carousel-control-next:hover,
    .carousel-control-prev:hover {
        background-color: transparent;
    }

    .carousel-indicators {
        position: relative;
        bottom: 0;

        button {
            background-color: #CFD0DD;
            width: 3rem;
            height: 0.3rem;
        }
        button.active {
            background-color: $secondary;
        }
    }
}


@include media-breakpoint-down(md) {
    .carousel {
        .carousel-slide {
            h3 {
                font-size: 1.5rem !important;
            }
            p {
                font-size: 1rem;
            }
            img {
                width: 100%;
            }
        }

        .carousel-inner {
            width: 100%;
        }
    }
}