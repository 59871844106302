@import '../../../index.scss';


.vision-and-mission-section {
    padding: 4rem;

    i {
        font-size: 4rem;
        color: $secondary;
    }

    .chip {
        font-size: 1.3rem;
    }
};

@include media-breakpoint-down(md) {
    .vision-and-mission-section {
        padding: 2rem;
    }
}