@import '../../index.scss';


.footer {
    padding: 2rem 4rem 2rem 4rem;
    font-weight: 400;
    background-color: $tertiary;
    color: white;
    position: absolute;
    bottom: 0;
    height: 5rem;
    width: 100%;

    a {
        color: unset;
        text-decoration: none;
    }

    a:hover {
        color: $default-text-hover-color;
    }
}

@include media-breakpoint-down(md) {
    .footer {
        padding: 2rem;
        margin: 0;
        height: 9rem;

        a {
            font-size: 1rem;
        }
    }
}