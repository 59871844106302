@import '../../../index.scss';


.why-tanaos-section {
    padding: 4rem;

    h3 {
        font-size: 1.5rem;
    }

    .icon-container {
        background-color: $secondary;
        width: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        
        i {
            color: white;
            font-size: 3rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .why-tanaos-section {
        padding: 2rem;
    }

    a {
        font-size: 1.2rem;
    }
}