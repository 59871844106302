@import '../../../index.scss';


.case-studies-section {
    padding: 4rem;
}

@include media-breakpoint-down(md) {
    .case-studies-section {
        padding: 2rem;
    }
}