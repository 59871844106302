@import '../../index.scss';


.pricing-card {
    padding: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 1rem;
    border: 1px solid #e6e6e6;

    .plan-name {
        font-size: 2rem;
        color: $secondary;
        font-weight: 600;
    }

    .plan-description {
        font-size: 1.1rem;
    }

    .btn {
        width: 100%;
        font-size: 1.1rem;
        padding: 0.6rem;
    }

    .feature {
        font-size: 1.1rem;
        line-height: 1.8rem;
        font-weight: 600;

        i {
            color: $secondary;
            font-size: 1.8rem;
        }
    }

    .excluded-feature {
        font-size: 1.1rem;
        line-height: 1.8rem;
        color: rgb(182, 181, 181);
        font-weight: 600;

        i {
            color: rgb(182, 181, 181);
            font-size: 1.5rem;
        }
    }
}