$primary: #ffedde;

$secondary: #ff3668;
$secondary-hover: #df0038;

$tertiary: #45474b;
$tertiary-hover: #6b6f75;

$default-text-color: black;
$default-text-hover-color: #adb1b7;

$default-border-radius: 1rem;