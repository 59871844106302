@import '../../index.scss'; 


.get-started-section {
    padding: 4rem;
    background-color: $primary;

    .link {
        cursor: pointer;
    }
}

@include media-breakpoint-down(md) {
    .get-started-section {
        padding: 2rem;
    }
}