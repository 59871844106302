@import '../../../index.scss';


.blog-page-hero-section {
    padding: 4rem;
    background-color: $primary;
    background-image: url(../../../assets/hero_background.png);
    background-repeat: no-repeat;
    background-position: 110%;

    button {
        font-size: 1.2rem;
    }
}

@include media-breakpoint-down(md) {
    .blog-page-hero-section {
        padding: 2rem;
        background-image: none;
    }
}