@import '../../index.scss';


.navbar {
    background-color: $primary;
    position: sticky;
    top: 0;
    z-index: 2;
    font-size: 1rem;
    padding: 1rem 3rem 1rem 3rem;

    .logo {
        cursor: pointer;
        width: 10%;
    }

    a {
        color: unset;
        text-decoration: none;
    }

    a:hover {
        color: $default-text-hover-color;
    }
}

.navbar-toggle-icon {
    font-size: 2rem;
    color: $default-text-color;
}

.navbar-collapse {
    display: none;
    flex-direction: column;
    padding: 2rem;
    position: absolute;
    top: 100%;
    left: 0;
}

.navbar-collapse.menu-active {
    display: block;
    background-color: $primary;
    border-top: 0.01rem solid gray;
    height: 110vh;
}

.navbar-nav {
    list-style: none;
    padding: 0;
}

.nav-item {
    margin-bottom: 0.5rem;
}

.nav-link {
    text-decoration: none;
    color: $default-text-color;
    font-size: 1.2rem;
    transition: color 0.3s;
}


@include media-breakpoint-down(md) {
    .navbar {
        padding: 1.5rem;

        .logo {
            width: 30%;
        }
    }
}