@import '../../../index.scss';


.pricing-cards-section {
    padding: 4rem;
}

@include media-breakpoint-down(md) {
    .pricing-cards-section {
        padding: 2rem;
    }
}