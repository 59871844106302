@import '../../index.scss';


.not-found-page {
    padding: 10% 15% 10% 15%;

    h1 {
        font-size: 2.3rem;
        font-weight: 700;
        margin-bottom: 4rem;
    }

    h2 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 4rem;
    }

    button {
        font-size: 1.2rem;
        padding: 1rem 2rem;
    }
}

@include media-breakpoint-down(md) {
    
    .not-found-page {
        padding: 20% 10% 10% 10%;
    }

}