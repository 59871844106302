@import '../../../index.scss';


.how-to-use-section {
    padding: 4rem;

    img {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    }
}

@include media-breakpoint-down(md) {
    .how-to-use-section {
        padding: 2rem;
    }
}