@import '../../../index.scss';


.values-section {
    padding: 4rem;

    .value-number {
        font-size: 5rem;
        color: $secondary;
        font-weight: 600;
    }

    h3 {
        font-size: 1.7rem;
        color: $secondary;
    }
};

@include media-breakpoint-down(md) {
    .values-section {
        padding: 2rem;
    }
}