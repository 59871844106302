@import '../../index.scss';


.feature-card {
    padding: 1rem;
    border-radius: $default-border-radius;
    background-color: white;
    border: none;

    .image-container {
        min-height: 22rem;

        img {
            width: 90%
        }
    }

    .category {
        letter-spacing: 0.05rem;
    }

    h3 {
        font-size: 1.6rem;
        font-weight: 700;
    }

    button {
        width: 100%;
    }
}

.feature-card.colored {
    background-color: $primary !important;
}


@include media-breakpoint-down(md) {

    .feature-card {
        .image-container {    
            img {
                width: 100%
            }
        }
    }

}