@import '../../../index.scss';


.articles-section {
    padding: 4rem 4rem 2rem 4rem;
}

@include media-breakpoint-down(md) {
    .articles-section {
        padding: 2rem;
    }
}