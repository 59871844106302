@import '../../index.scss';


.blog-article-page {
    h1 {
        font-size: 2.8rem;
    }

    .docs-article-date {
        font-size: 1.2rem;
        color: rgb(87, 87, 87);
        font-weight: 300;
    }

    .article-component {
        padding: 4rem 10rem 4rem 10rem;
        font-size: 1.2rem;

        li {
            margin-top: 0.5rem;
        }
    }

    p {
        margin-top: 1.5rem;
    }
}

@include media-breakpoint-down(md) {
    .article-component {
        padding: 2rem !important;
    }
}