@import '../../../index.scss';


.hero-section {
    padding: 2rem 1rem 4rem 4rem;
    background-color: $primary;

    img {
        width: 90%;
    }
}

@include media-breakpoint-down(md) {

    .hero-section {
        padding: 2rem 1rem 4rem 1rem;
        margin: 0;
    }
  
}