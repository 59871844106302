@import '../../index.scss';


.blog-thumbnail {
    img {
        width: 100%;
        cursor: pointer;
    }

    .date {
        color: #646369;
    }

    .title {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        cursor: pointer;
    }
    .title:hover {
        text-decoration: underline;
    }

    .description {
        color: #646369;
        font-size: 0.9rem;
    }
}