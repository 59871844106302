@import './variables.scss';

// Required
@import '/node_modules/bootstrap/scss/functions';
@import '/node_modules/bootstrap/scss/variables';
@import '/node_modules/bootstrap/scss/mixins';


body {
    margin: 0;
    font-family: 'Lato', sans-serif !important;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    min-height: 100vh;
    padding-bottom: 5rem;
}

code {
    background-color: rgb(240, 240, 240);
    border-radius: 3px;
    padding: 0.2em 0.4em;
    font-family: monospace;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    color: black;
}

.hero-title {
    font-size: 2.8rem;
    font-weight: 600;
}

.hero-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.5rem;
}

h2 {
    font-size: 2.3rem;
    font-weight: 700;
}

h3 {
    font-size: 2rem;
    font-weight: 700;
}

h4 {
    font-size: 1.7rem;
    font-weight: 700
}

h5 {
    font-size: 0.9rem;
}

p {
    font-weight: 400;
    font-size: 1.2rem;
}

b {
    font-weight: 900;
}

a {
    color: $secondary;
}

a:hover {
    color: $secondary-hover;
}

.btn-primary {
    background-color: $tertiary;
    border: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: $tertiary-hover !important;
}

.btn-light {
    background-color: white;
    color: black;
    border: 0.05rem solid gray;
}

.btn-secondary {
    background-color: $secondary;
    color: white;
    border: none;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    background-color: $secondary-hover !important;
}

.chip {
    background-color: $secondary;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    border-radius: 0.3rem;
    width: fit-content;
    font-weight: 700;
    color: white;
}

.mobile-only {
    display: none !important;
}

.colored {
    background-color: $secondary;
    color: white;
    padding: 0.1rem 0.5rem;
    border-radius: 0.5rem;
}

@include media-breakpoint-down(md) {

    body {
        padding-bottom: 9rem;
    }

    .mobile-only {
        display: unset !important;
    }

    .desktop-only {
        display: none;
    }
}